import React from 'react'

const Projects = () => {
    return (
        <section className='section-t' id="projects">
            <div className="zrb-container">
                <div className="section-head">
                    <h1>Projects <span>Showcase</span></h1>
                </div>
            </div>
        </section>)
}


export default Projects;